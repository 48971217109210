* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  margin: 0 auto;
  min-width: 1440px;
  max-width: 1920px;
  overflow-x: hidden;
}

@media (max-width: 800px) {
  #root {
    max-width: none;
    min-width: auto;
    width: 375px;
  }
}
